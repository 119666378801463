export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.hook("vuetify:configuration", vuetify => {
    vuetify.vuetifyOptions = vuetify.vuetifyOptions || {};
    vuetify.moduleOptions = vuetify.moduleOptions || { styles: { configFile: "./styles/variables.scss" } };
    Object.assign(vuetify.vuetifyOptions, {
      theme: {
        defaultTheme: "light",
        themes: {
          light: {
            dark: false,
            colors: {
              background: "#fff", // for background color
              surface: "#1C829B", // "#009fae", // for card color
              primary: "#FFF",
              rating: "#f4c74d",
              button: "#3a96aa",
              breadcrumbs: "#FFF",
              // cardTitle: "#dde7e9",
              linkColor: "#009fae", // "#00464D", //"#165865",
              footerLinkColor: "#fff",
              jackpot: "#3a96aa",
              buttonTextColor: "#ffffff",
              buttonBackground: "#3a96aa",
              iconColor: "#3a96aa",
              toTop: "#f3f3f3",
              toTopHover: "#164162",
              footerLink: "#FFFFFF",
              promoCategoriesLinks: "#000000",
            },
          },
          dark: {},
        },
      },
    });
  });
});
